<template>
    <div class="main-box">
        <el-steps class="step-box" direction="vertical" space="50px" :active="activeStep">
            <el-step v-for="(item, index) in stepsArr" :key="index" :title="item.title"
                     @click.native="jump(item)"></el-step>
        </el-steps>
        <div class="main-box-contain box1">
            <div class="top-card">
                <div class="top-card-contain">
                    <div class="right-card-box">
                        <div class="box-title" id="box-title1">建设思路</div>
                    </div>
                </div>
            </div>
            <div class="bottom-card">
                <div class="bottom-card-list">
                    <div :class="index % 2===0?'card-list-item':'card-list-item right'"
                         v-for="(item,index) in cardList1"
                         :style="{backgroundImage:'url('+ item.back +')'}">
                        <div class="card-left">
                            <img :src="item.image" alt="">
                        </div>
                        <div class="card-right">
                            <div class="card-right-contain">
                                <div class="card-title">{{item.title}}</div>
                                <div class="card-content">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box-contain box3">
            <div class="top-box-content">
                <div class="box-title" id="box-title2">建设内容</div>
            </div>
            <div class="bottom-box-content">
                <div class="title-box left">
                    <div class="title-index">01</div>
                    <div class="title-text">京东产教融合实训基地</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        京东产教融合实训基地规划总面积100平方米，基于京东真实工作环境进行建设，充分考虑教师教学、学生学习、京东实战的需求，共设置50工位，每工位配备台式电脑、IP电话机、电脑桌，从环境布置上更方便学生进行团队协作。
                    </div>
                    <div class="main-contain-text" style="margin-top: 0">
                        京东产教融合实训基地引入京东电商技术课程、京东1:1仿真前后台系统来提升学生的实训实践能力。通过京东智能课堂平台完成“教学、实训、实战、实体”四位一体人才培养模式。帮助学生进行电子商务运营实训、电子商务客户服务实训等，熟悉电子商务各项活动的流程，掌握电子商务的各种操作技能，加深对电子商务的感性认识，培养实践操作能力强、管理水平高的综合应用型商务人才。
                    </div>
                    <div class="main-contain-text" style="margin-top: 0">
                        同时基地对接京东客服实战项目，用最真实的业务流程体现给学生面前，在实践中提升了与人沟通的能力、处理困难的抗压能力以及高度的战略整合能力，多方面的训练了学生综合能力，给学生提供更多的就业选择机会。
                    </div>
                    <div class="double-image-box">
                        <div class="left-image">
                            <img src="../../assets/images/education/jd-card5.png" alt="">
                        </div>
                        <div class="right-image">
                            <img src="../../assets/images/education/jd-card6.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-box-content">
                <div class="title-box right">
                    <div class="title-index">02</div>
                    <div class="title-text">京东电商技术课程体系搭建</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        京东电商技术课程采取“情境导入、任务驱动”的开发思路，基于京东真实的项目，共开发8门课程，共计500课时。课程研发采取双向互动教学模式：教师具备（教学安排、课程大纲、讲稿、课件、课堂沙龙、教师手册等教学资源）、学生具备（学生手册、实训指导书、习题等学习资源）。
                    </div>
                    <div class="main-contain-text" style="margin-top: 0">
                        京东电商技术课程以京东线上商城为案例深度进行讲授，结合理论和思路讲解，穿插案例说明带领新手学员入门。系列课程从店铺开设、店铺装修、商品管理来讲解京东开店的基本技能；然后讲解店铺引流、店铺营销、数据分析及搜索引擎优化培养学生学会京东店铺运营技能；最后讲解到仓配客系统及工作流程教会学生掌握京东电商的物流发货过程，使学生掌握整个京东电商服务技能。
                    </div>
                    <div class="img-title-text">学生手册</div>
                    <div class="img-box">
                        <img src='../../assets/images/education/jd-card7.png' alt="">
                    </div>
                    <div class="img-title-text">教师手册</div>
                    <div class="img-box">
                        <img src='../../assets/images/education/jd-card8.png' alt="">
                    </div>
                    <div class="bottom-box-content">
                        <div class="title-box left">
                            <div class="title-index">03</div>
                            <div class="title-text">京东1:1仿真前后台系统</div>
                        </div>
                        <div class="main-contain">
                            <div class="main-contain-text">
                                京东产教融合实训基地通过引入京东1:1仿真前后台系统与京东电商技术课程内容对应的的岗位基础项目、岗位核心项目、综合实战项目，包含必备的资源包、素材库以及实训指导书。本校的学生结合自己所学的电商美工知识，设计并制作产品主图、商品详情页和细节图等，并上传至京东个人店铺；结合网店运营的知识，对产品的标题进行组合，通过选词、筛词、组词拟出具备高点击率的产品标题，真实的货源搭配真实的网店运营，能够有效地让学生体验电商运营的全套流程，结合客户服务的知识，针对买家的提问进行沟通解答，并实践各种沟通技巧和客服管理工具的使用，学生可自主训练模仿，快速提升实操能力。
                            </div>
                            <div class="main-contain-text" style="margin-top: 0">
                                此外，京东1:1仿真前后台系统兼具教学功能，学生在教师的指导下进行店铺的开设、装修、产品上架、营销、订单管理等操作，教师以平台管理者的身份对学生的店铺进行管理，可以推荐学生商品进入促销频道和平台首页展示。学生可以报名京东秒杀、每日特价、品牌闪购等营销模块增加学生店铺的推广渠道，使学生充分掌握京东平台店铺的核心流程与重难点。
                            </div>
                            <div class="img-box" style="padding-top: 90px;">
                                <img src='../../assets/images/education/jd-card9.png' alt="">
                            </div>
                        </div>
                    </div>
                    <div class="card-list">
                        <div :class="index%2===0?'card-list-item':'card-list-item right'"
                             v-for="(item,index) in cardList2">
                            <div class="card-item-left">
                                <div class="card-left-contain">
                                    <div class="card-title">{{item.title}}</div>
                                    <div class="card-content">{{item.content}}</div>
                                </div>
                            </div>
                            <div class="card-item-right">
                                <img :src="item.image" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="bottom-box-content">
                        <div class="title-box right">
                            <div class="title-index">04</div>
                            <div class="title-text">“京东班”人才培养模式搭建</div>
                        </div>
                        <div class="main-contain">
                            <div class="main-contain-text">
                                根据京东及电商行业最新的技能要求、用人需求，牢牢把握校企合作这一职业教育核心理念，坚持走“校企合作、产教融合”的路子，引企入校，坚持以培养学生合格的电子商务职业能力为基本目标与京东联合设立“京东班”，以京东及京东友商电商企业需求大数据为基础建立京准人才评测库，制定与行业需求紧密结合的专业人才培养方案，通过京东电商技术课程体系落实专业人才培养，按照“教学、实训、实战、实体”四位一体人才培养模式制定教学计划，以三年学制计算，保证实训课时占总课时50%以上。
                            </div>
                            <div class="main-contain-text" style="margin-top: 0">
                                同时京东将会引入国际先进管理标准及行业先进Genesys系统、京东客服网络专线、京东集团物流客服项目以及三名京东实战老师帮助学校建立以客户满意为目标的京东客服人才培养模式。并通过京准人才评测系统实时学生在校期间的主要学习数据形成人才能力多维模型和京准人才评测库，创新人才输出模式，不断完善人才评定标准库，以此实现人才培养方案的动态调整，促成学校、学生、京东三方动态平衡。
                            </div>
                            <div class="img-box">
                                <img src='../../assets/images/education/jd-card13.png' alt="">
                            </div>
                            <div class="double-image-box">
                                <div class="left-image">
                                    <img src="../../assets/images/education/jd-card14.png" alt="">
                                </div>
                                <div class="right-image">
                                    <img src="../../assets/images/education/jd-card15.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box-contain box4">
            <div class="bottom-box-content">
                <div class="title-box left">
                    <div class="title-index">05</div>
                    <div class="title-text">“百年京师”建设</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        京东“百年京师”师资培训服务协助院校在校企合作、工学结合、人才培养等领域进行深化融合，把京东电商技术课程通过与院校需求做一个完美的结合让学校培养出的学生更适合社会的发展需求。
                    </div>
                    <div class="main-contain-text" style="margin-top: 0">
                        京东官方认证导师赴校进行电商专业实操实训课程教学和指导，教师到京东集团总部进行培训，帮助教师获得独立教学企业课程能力。
                    </div>
                    <div class="img-box">
                        <img src='../../assets/images/education/jd-card16.png' alt="">
                    </div>
                </div>
            </div>
            <OfficialFooter style="background: rgba(3,3,47,0.85)"/>
        </div>
    </div>
</template>

<script>
    import OfficialFooter from "../OfficialFooter";

    export default {
        name: "JD",
        components: {
            OfficialFooter
        },
        data() {
            return {
                stepsArr: [
                    {title: '建设思路', to: 'box-title1'},
                    {title: '建设内容', to: 'box-title2'},
                ],
                activeStep: 1,
                scrollTop: 0,
                cardList1: [
                    {
                        title: '京东电商技术课程体系建设',
                        image: require('../../assets/images/education/jd-card1.png'),
                        back: require('../../assets/images/education/jd-card1-back.png'),
                        content: '以“岗位引导、项目驱动”为出发点，基于京东集团电子商务工作流程系统化开发构建理论基础课、岗位基础课、岗位核心课，结合电商企业岗位胜任力模型及任职标准，引入京东电商技术课程，建设旨在培养学生人文素养、专业技能、创新能力和职业精神的课程体系。'
                    },
                    {
                        title: '教学技术应用创新 ',
                        image: require('../../assets/images/education/jd-card2.png'),
                        back: require('../../assets/images/education/jd-card2-back.png'),
                        content: '通过京东智能课堂平台，实现教学、实训、实战、考试、测评等课堂教学活动的互联网化，并利用互联网的同步性实现教学资源与电商行业需求无缝连接。'
                    },
                    {
                        title: '　师资队伍建设',
                        image: require('../../assets/images/education/jd-card3.png'),
                        back: require('../../assets/images/education/jd-card3-back.png'),
                        content: '加强学校和企业双师带头人培养，制定不同层次专兼职教师的培养计划，采取企业实践、校企交流等多种形式，通过参加“百年京师，双师同行”京东客户服务中心双师培训班的培训及资格认证。通过线上考试+试讲形式进行考核，从而打造一支实战能力高超的师资队伍，帮助学校高效运转产教融合实训基地，培养电商运营、网店美工、网络营销、客服、新媒体等岗位人才。'
                    },
                    {
                        title: '产教融合深度发展体制机制创新',
                        image: require('../../assets/images/education/jd-card4.png'),
                        back: require('../../assets/images/education/jd-card4-back.png'),
                        content: '健全以企业为重要主导、高校为重要支撑、产业关键核心技术攻关为中心任务的高等教育产教融合创新机制。依托京东集团在电子商务行业领域的优势设立京东班，以京东客服、美工实战、新媒体等实战项目带入等形式，由院校教师与京东师资联合培养京东班学生。从而促进院校电子商务专业快速发展，推动产业转型升级；力求形成以“招工即招生、入企即入校、企校双向联合培养”为主要内容的校企联合人才培养机制，积极为京东及社会培养、输送一大批电子商务技术技能人才，努力实现院校与京东的合作共赢、共同发展之目的。'
                    },
                ],
                cardList2: [
                    {
                        title: '店铺装修实训项目',
                        image: require('../../assets/images/education/jd-card10.png'),
                        content: '系统店铺装修模块1:1仿真真实京东店铺装修模块，配套上百套店铺装修素材给学生进行挑选。学生可凭自身装修意愿随意打造属于自己的京东店铺。'
                    },
                    {
                        title: '商品处理项目',
                        image: require('../../assets/images/education/jd-card11.png'),
                        content: '京东配备上万个商品SKU及图片信息结合系统中商品管理模块，学生可自定义上传商品到个人店铺中。并对商品图片进行精修处理等工作，帮助学生快速的进行针对性的选品训练，提高学生图片处理速度及质量。'
                    },
                    {
                        title: '店铺运营项目\n',
                        image: require('../../assets/images/education/jd-card12.png'),
                        content: '系统提供数十种与店铺运营实训指导书配套的京东营销工具及营销活动，全过程全流程仿真京东运营规则及玩法让学生不用花钱也可体验到京东的运营规则解决学生实训店铺运营的难题。'
                    },
                ],
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('scroll', this.onScroll, true)
            })
        },
        methods: {
            // 滚动触发按钮高亮
            onScroll(e) {
                let scrollItems = document.querySelectorAll('.box-title')
                for (let i = scrollItems.length - 1; i >= 0; i--) {
                    // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
                    let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop
                    if (judge) {
                        this.activeStep = i + 1
                        break
                    }
                }
            },
            // 点击切换锚点
            jump(item) {
                document.getElementById(item.to).scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block: "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-box {
        width: 100%;

        .step-box {
            position: sticky;
            float: left;
            height: 200px;
            top: 100px;
            z-index: 1;
            cursor: pointer;
        }

        .main-box-contain {
            background-image: url("../../assets/images/education/circle.png");
            background-repeat: no-repeat;
            background-size: cover;

            .box-title {
                text-align: center;
                font-size: 30px;
                color: #333333;
                position: relative;

                &:after {
                    content: '';
                    width: 50px;
                    height: 4px;
                    background: #584EEE;
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    margin-left: -20px;
                }
            }

            .bottom-box-content {
                padding-top: 60px;
                width: 1150px;
                margin: 0 auto;

                .title-box {
                    display: flex;
                    flex-direction: column;
                    text-align: right;

                    &.left {
                        align-items: flex-start;
                    }

                    &.right {
                        align-items: flex-end;
                    }

                    .title-index {
                        width: 94px;
                        height: 94px;
                        background: linear-gradient(129deg, #60C4FF 0%, #717EFE 100%);
                        font-weight: bold;
                        font-size: 72px;
                        color: #FFFFFF;
                        line-height: 120px;
                    }

                    .title-text {
                        font-size: 20px;
                        color: #444444;
                        line-height: 36px;
                        margin-top: 10px;
                    }
                }

                .main-contain {
                    width: 1150px;
                    padding-top: 30px;
                    margin: 0 auto;

                    .main-contain-text {
                        margin-top: 40px;
                        text-indent: 2em;
                        font-size: 14px;
                        color: #444444;
                        line-height: 34px;
                    }

                    .double-image-box {
                        position: relative;
                        display: flex;
                        height: 480px;
                        margin-top: 70px;

                        .right-image {
                            position: absolute;
                            left: 470px;
                            top: 80px;
                        }
                    }

                    .img-box {
                        padding: 40px 0 30px;
                        text-align: center;
                    }

                    .img-title-text {
                        margin-top: 130px;
                        font-size: 20px;
                        color: #444444;
                        font-weight: 300;
                        line-height: 36px;
                    }
                }
            }

            .card-item {
                width: 453px;
                min-height: 596px;
                background: #FFFFFF;
                box-shadow: 0px 0px 31px 1px rgba(5, 4, 67, 0.15);
                opacity: 0.9;
                z-index: 1;
                margin-right: 60px;

                .top-item-box {
                    height: 283px;
                    position: relative;

                    .top-item-title {
                        width: 100%;
                        height: 39px;
                        background: #000000;
                        opacity: 0.5;
                        display: flex;
                        color: #FFFFFF;
                        font-size: 12px;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: 0;
                    }
                }

                .bottom-item-box {
                    .bottom-item-contain {
                        padding: 30px 30px 40px;

                        .bottom-item-title {
                            font-size: 14px;
                            color: #222222;
                            line-height: 28px;
                            text-align: center;
                        }

                        .bottom-item-content {
                            margin-top: 30px;
                            font-size: 12px;
                            color: #444444;
                            line-height: 27px;
                            text-indent: 2em;
                        }
                    }
                }
            }

            &.box1 {
                background-color: #ffffff;

                .top-card {
                    width: 1150px;
                    margin: 0 auto;

                    .top-card-contain {
                        padding-top: 30px;
                        text-align: center;

                    }
                }

                .bottom-card {
                    margin: 0 auto;
                    padding-bottom: 120px;

                    .bottom-card-list {
                        width: 1150px;
                        margin: 0 auto;
                        display: flex;
                        flex-direction: column;

                        .card-list-item {
                            display: flex;
                            margin-right: 30px;
                            margin-top: 40px;
                            display: flex;
                            flex-direction: column;
                            background-repeat: no-repeat;
                            background-position: 330px 20px;

                            &.right {
                                align-items: flex-end;
                                background-position: 720px 20px;
                            }

                            .card-left {
                                text-align: center;
                                width: 700px;
                            }

                            .card-right {
                                width: 700px;
                            }

                            .card-right-contain {
                                padding-top: 30px;

                                .card-title {
                                    font-size: 16px;
                                    font-weight: 300;
                                    color: #222222;
                                    line-height: 36px;
                                    text-align: center;
                                }

                                .card-content {
                                    margin-top: 20px;
                                    padding-bottom: 40px;
                                    font-size: 14px;
                                    color: #666666;
                                    font-weight: 300;
                                    line-height: 30px;
                                }
                            }
                        }
                    }

                    .bottom-img-contain {
                        width: 1150px;
                        height: 394px;
                        background: #F7FAFB;
                        margin: 50px auto 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            &.box3 {
                background: #ffffff;
                padding-bottom: 90px;

                .top-box-content {
                    width: 1150px;
                    margin: 0 auto;
                    padding-top: 50px;
                }

                .card-list {
                    width: 1150px;
                    margin: 0 auto;
                    padding-top: 100px;

                    .card-list-item {
                        display: flex;
                        height: 400px;
                        margin-top: 30px;
                        position: relative;

                        &:first-child {
                            margin-top: 0;
                        }

                        &.right {
                            flex-direction: row-reverse;

                            .card-item-right {
                                left: -16px;;
                            }
                        }

                        .card-item-left {
                            width: 465px;
                            background: #F7FAFB;

                            .card-left-contain {
                                padding: 50px 60px 0 50px;
                                line-height: 34px;
                                text-align: center;

                                .card-title {
                                    font-size: 18px;
                                    color: #222222;
                                }

                                .card-content {
                                    font-size: 14px;
                                    color: #444444;
                                    margin-top: 10px;
                                }
                            }
                        }

                        .card-item-right {
                            height: 434px;
                            position: absolute;
                            top: -16px;
                            right: -16px;
                        }

                    }
                }

                .bottom-image-box {
                    width: 1150px;
                    margin: 60px auto 0;
                    background: #F7FAFB;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &.box4 {
                background-color: #FFFFFF;
                background-image: url("../../assets/images/about/lift-hand.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center bottom;

                .bottom-box-content {
                    padding-bottom: 100px;
                }

            }
        }
    }

</style>