<template>
    <div class="education-contain">
        <el-scrollbar class="contain-box">
            <div class="top-box">
                <left-tab/>
                <div class="top-title">
                    <div class="title-box">
                        <div class="title-text">{{title}}</div>
                        <div class="title-info">{{description}}</div>
                    </div>
                </div>
            </div>
            <div class="about-menu" id="aboutMenu">
                <div class="menu-item-box">
                    <div @click="selectTab(item.id)" v-for="item in tabList" :key="item.id"
                         :class="tabIndex === item.id ? 'about-menu-item menu-active' : 'about-menu-item'">
                        <span>{{item.name}}</span>
                    </div>
                </div>
            </div>
            <div :class="className">
                <div class="about-content">
                    <new-media v-if="tabIndex === 1"/>
                    <new-retail v-if="tabIndex === 2"/>
                    <j-d v-if="tabIndex === 3"/>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import leftTab from "@/components/education/leftTab";
    import newMedia from '@/components/education/newMedia'
    import newRetail from '@/components/education/newRetail'
    import JD from '@/components/education/JD'

    export default {
        name: "Solution",
        components: {
            leftTab,
            newMedia,
            newRetail,
            JD
        },
        data() {
            return {
                title: '解决方案',
                description: '一课教育为院校提供最全面最专业的电子商务整体解决方案。包括：产教融合、新媒体、专业建设、资源库、现代学徒制、特色专业群、新零售等领域。',
                tabIndex: 1,
                tabList: [
                    {
                        id: 1,
                        name: '新媒体电商产教融合实践基地'
                    },
                    {
                        id: 2,
                        name: '新零售产教融合基地'
                    },
                    {
                        id: 3,
                        name: '京东产教融合基地'
                    },
                ]
            }
        },
        mounted() {
            if (this.$route.query.contactUs) {
                this.$nextTick(() => {
                    this.tabIndex = Number(this.$route.query.contactUs);
                    this.$el.querySelector(`#aboutMenu`).scrollIntoView({
                        behavior: "smooth",  // 平滑过渡
                        block: "start"  // 上边框与视窗顶部平齐。默认值
                    });
                })
            }
        },
        computed: {
            className() {
                let name = '';
                switch (this.tabIndex) {
                    case 1:
                        name = 'about-content-bj-one';
                        break;
                    case 2:
                        name = 'about-content-bj-two';
                        break;
                    case 3:
                        name = 'about-content-bj-three';
                        break;
                }
                return name;
            }
        },
        methods: {
            selectTab(num) {
                this.tabIndex = num
            },
            amendTabIndex() {
                this.tabIndex = 4;
                this.$el.querySelector(`#aboutMenu`).scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block: "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-contain {
        height: 100%;
        width: 100%;

        .contain-box {
            height: 100%;
            width: 100%;
            min-width: 1500px;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .top-box {
            width: 100%;
            height: 466px;
            position: relative;
            background-image: url("../../../assets/images/education/solution.png");
            background-repeat: no-repeat;
            background-size: cover;

            .top-title {
                width: 1200px;
                margin: 0 auto;
                padding-top: 110px;

                .title-box {
                    display: flex;
                    flex-direction: column;

                    .title-text {
                        text-align: center;
                        font-size: 45px;
                        color: #FFFFFF;
                    }

                    .title-info {
                        padding: 0 245px;
                        line-height: 30px;
                        font-size: 18px;
                        color: #FFFFFF;
                        margin-top: 40px;
                    }
                }
            }
        }

        .about-menu {
            display: flex;
            width: 100%;
            min-width: 1200px;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px 1px rgba(62, 58, 234, 0.1);
            position: relative;

            .menu-item-box {
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-around;

                .about-menu-item {
                    line-height: 90px;

                    span {
                        font-size: 18px;
                        color: #222222;
                        font-weight: 300;
                        cursor: pointer;
                    }
                }

                .menu-active {
                    position: relative;

                    span {
                        color: #584EEE;
                    }

                    &:after {
                        content: '';
                        width: 40px;
                        height: 4px;
                        background: #584EEE;
                        border-radius: 4px 4px 0px 0px;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -20px;
                    }
                }
            }
        }

        .about-content {
            width: 100%;
        }

        .about-content-bj-one {
            background-color: #fff;
            display: flex;
            background-image: url('./../../../assets/images/about/lift-hand.jpg');
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: 0 100%;
        }

        .about-content-bj-two {
            background-color: #fff;
            display: flex;
            background-image: url('./../../../assets/images/about/course-bj.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: 0 100%;
        }

        .about-content-bj-three {
            background-color: #fff;
            display: flex;
            background-image: url('./../../../assets/images/about/honor-bj.jpg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: 0 100%;
        }
    }
</style>