<template>
    <div class="main-box">
        <el-steps class="step-box" direction="vertical" space="50px" :active="activeStep">
            <el-step v-for="(item, index) in stepsArr" :key="index" :title="item.title"
                     @click.native="jump(item)"></el-step>
        </el-steps>
        <div class="main-box-contain box1">
            <div class="top-card">
                <div class="top-card-contain">
                    <div class="right-card-box">
                        <div class="box-title" id="box-title1">建设思路</div>
                        <div class="card-text">
                            按照从“创业技能实训”“创业项目孵化”的人才培养思路进行建设，产教融合基地既可作为日常教学时的实训实战，也可作为学生的创新创业平台。新零售技能实训中心、新零售体验中心、新零售物流中心、新零售创新创业中心四大服务中心，担负校内电商专业的实训实战功能，以及全校各专业学生创新创业孵化功能，力求提升院校的整体教学水平、教学质量及院校竞争力。
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-card">
                <div class="bottom-card-contain">
                    <img src="../../assets/images/education/retail-card1.png" alt="">
                </div>
                <div class="bottom-card-list">
                    <div class="card-list-item" v-for="item in cardList1">
                        <div class="card-left">
                            <img :src="item.image" alt="">
                        </div>
                        <div class="card-right">
                            <div class="card-right-contain">
                                <div class="card-title">{{item.title}}</div>
                                <div class="card-content">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-img-contain">
                    <img src="../../assets/images/education/retail-card6.png" alt="">
                </div>
            </div>
        </div>
        <div class="main-box-contain box2">
            <div class="top-box-content">
                <div class="box-title" id="box-title2">建设目标</div>
            </div>
            <div class="main-box">
                <div class="main-contain">
                    <div class="main-left">
                        凝聚“行、企、校、生”共同的力量建设集“教育教学、企业真实生产经营、人才培养、创新创业、社会技术培训、社区服务”为一体的新零售产教融合基地。通过新零售创新创业中心源源不断产出孵化更多项目，即优化“产教融合，立体化实践”的商科人才培养模式，也体现了教育链、人才链与产业链、创新链的有机衔接，助力实体零售向智慧化、数字化转型升级，增强高职教育服务地方经济社会发展的能力，促进区域现代服务业（商贸零售业）高质量发展。
                    </div>
                    <div class="main-right">
                        <img src="../../assets/images/education/retail-card7.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box-contain box3">
            <div class="top-box-content">
                <div class="box-title" id="box-title3">建设内容</div>
            </div>
            <div class="bottom-box-content">
                <div class="title-box left">
                    <div class="title-index">01</div>
                    <div class="title-text">新零售技能实训中心</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        新零售技能实训中心规划面积120㎡，完全按照企业真实工作环境进行建设，充分考虑教师教学和学生学习需求，配置交互智能教学一体机、电脑桌，共设置50工位，每工位配备电脑，从环境布置上更方便学生进行团队协作。
                    </div>
                    <div class="double-image-box">
                        <div class="left-image">
                            <img src="../../assets/images/education/retail-card8.png" alt="">
                        </div>
                        <div class="right-image">
                            <img src="../../assets/images/education/retail-card9.png" alt="">
                        </div>
                    </div>
                    <div class="main-contain-text">
                        新零售技能实训中心引入《本土化农村电商》和《B2C商城运营与管理》《社交电商实战》课程、信息化教学平台、新零售门店管理系统。教师可以进行理论教学、实训训练、项目实战等教学活动，帮助学生掌握电子商务岗位所需的职业技能。通过课程学习与实训软件实际操练，学生能够系统的掌握新零售线上商城的店铺开设、商品拍摄与图片制作、订单管理、平台营销与推广等知识与技能。
                    </div>
                </div>
            </div>
            <div class="bottom-box-content">
                <div class="title-box right">
                    <div class="title-index">02</div>
                    <div class="title-text">新零售技能实训中心</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text" style="text-align: center">
                        新零售体验中心规划面积50㎡，按照真实线下智慧门店为标准进行建设。
                    </div>
                    <div class="img-box">
                        <img src='../../assets/images/education/retail-card10.png' alt="">
                    </div>
                    <div class="img-box">
                        <img src='../../assets/images/education/retail-card11.png' alt="">
                    </div>
                    <div class="card-list">
                        <div :class="index%2===0?'card-list-item':'card-list-item right'"
                             v-for="(item,index) in cardList2">
                            <div class="card-item-left">
                                <div class="card-left-contain">
                                    <div class="card-title">{{item.title}}</div>
                                    <div class="card-content">{{item.content}}</div>
                                </div>
                            </div>
                            <div class="card-item-right">
                                <img :src="item.image" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="bottom-box-content">
                        <div class="title-box left">
                            <div class="title-index">03</div>
                            <div class="title-text">新零售物流中心</div>
                        </div>
                        <div class="main-contain">
                            <div class="main-contain-text">
                                新零售物流中心规划面积为30㎡，下设接待区、等候区、仓储区三个功能区。配备快递扫描枪、货架、智能手机、电脑、柜台，可以满足快递录入与取出的需求。
                            </div>
                            <div class="main-contain-text" style="margin-top: 0">
                                新零售物流中心为新零售体验中心提供物流支持，保障新零售体验中心更好的落地，同时也可以作为新零售体验中心引流平台。新零售物流中心利用新零售门店管理系统中的快递代收功能运作，物流中心收到快递后通过微信公众号推送取件码给收货人，收货人凭取件码在物流中心取快递，同时我们可以在物流中心微信公众号上展示新零售体验中心的相关活动信息来吸引客户。
                            </div>
                            <div class="double-image-box">
                                <div class="left-image">
                                    <img src="../../assets/images/education/retail-card16.png" alt="">
                                </div>
                                <div class="right-image">
                                    <img src="../../assets/images/education/retail-card17.png" alt="">
                                </div>
                            </div>
                            <div class="main-contain-text">
                                新零售技能实训中心引入《本土化农村电商》和《B2C商城运营与管理》《社交电商实战》课程、信息化教学平台、新零售门店管理系统。教师可以进行理论教学、实训训练、项目实战等教学活动，帮助学生掌握电子商务岗位所需的职业技能。通过课程学习与实训软件实际操练，学生能够系统的掌握新零售线上商城的店铺开设、商品拍摄与图片制作、订单管理、平台营销与推广等知识与技能。
                            </div>
                        </div>
                    </div>
                    <div class="bottom-image-box">
                        <img src="../../assets/images/education/retail-card18.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box-contain box4">
            <div class="bottom-box-content">
                <div class="title-box right">
                    <div class="title-index">04</div>
                    <div class="title-text">新零售创新创业中心规划</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        新零售创新创业中心规划为80㎡，其中办公区50㎡、会议室30㎡。主要是作为基地创业团队办公场所，采取两个小组独立运营的竞赛机制。所以我们新零售创新创业中心设置12个工位，每个小组6个工位，按照企业真实运作流程配备相关办公用品、电脑、会议室等。
                    </div>
                    <div class="main-contain-text" style="margin-top: 0">
                        新零售创新创业中心通过引入电商创客实战平台软件（小程序）为学生创业提供平台支持，学生可以开设个人的小程序店铺，小程序作为目前主流的移动电商平台，依托微信强大的用户群体，能购让小程序店铺能更快的落地。
                    </div>
                    <div class="double-image-box">
                        <div class="left-image">
                            <img src="../../assets/images/education/retail-card19.png" alt="">
                        </div>
                        <div class="right-image">
                            <img src="../../assets/images/education/retail-card20.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <OfficialFooter style="background: rgba(3,3,47,0.85)"/>
        </div>
    </div>
</template>

<script>
    import OfficialFooter from "../OfficialFooter";

    export default {
        name: "newRetail",
        components: {
            OfficialFooter
        },
        data() {
            return {
                stepsArr: [
                    {title: '建设思路', to: 'box-title1'},
                    {title: '建设目标', to: 'box-title2'},
                    {title: '建设内容', to: 'box-title3'},
                ],
                activeStep: 1,
                scrollTop: 0,
                cardList1: [
                    {
                        title: '新零售技能实训中心',
                        image: require('../../assets/images/education/retail-card2.png'),
                        content: '为电商专业的老师提供相关专业课程及实训教学平台，通过针对相关岗位核心技能的实训技能训练与操作，促使学生将课程上的知识向实训技能迁移和过度。该中心是提高学生理论知识、实践能力的重要场所。'
                    },
                    {
                        title: '新零售体验中心 ',
                        image: require('../../assets/images/education/retail-card3.png'),
                        content: '让学生体验新时代新零售智慧门店的作业流程、工作气氛，实现在校“顶岗实习”，学生毕业后能与企业无缝衔接。同时为培养学生创新创业能力提供场地和平台支撑，使学生可以零距离的进行创业实践，掌握创业就业所需的各项技能，成为“一专多能”的应用型人才。'
                    },
                    {
                        title: '新零售物流中心',
                        image: require('../../assets/images/education/retail-card4.png'),
                        content: '为新零售体验中心提供物流支持，保障新零售体验中心更好的落地，同时也可以作为新零售体验中心线下引流平台。同时为全校学生提供大量的实战岗位，让学生在实战中锻炼岗位实战能力，提升自身就业竞争力。'
                    },
                    {
                        title: '新零售创新创业中心',
                        image: require('../../assets/images/education/retail-card5.png'),
                        content: '引入电商创客实战平台（小程序）为学生创业提供平台支持，学生可一键开设个人的小程序店铺，小程序作为目前主流的移动电商平台，依托微信强大的用户群体，能购让小程序店铺能更快的落地。该中心同时可承接企业的外包项目，包括美工类项目、运营类项目。'
                    },
                ],
                cardList2: [
                    {
                        title: '产品展示区',
                        image: require('../../assets/images/education/retail-card12.png'),
                        content: '产品展示区主要功能为线下体验与商品展示。通过线下展示商品的各种优势，以及优化客户体验，引导进店客户实际感知商品的品质和性能，从而促使顾客认知、喜好上购买，实现体验营销。同时，新零售体验中心可以选择企业提供货源，也可以结合本地资源特色（如农产品、特产、工艺品等），结合学生的线上商城，将当地资源特色推广至全国各地，增加当地资源的销路，推进区域经济的发展。'
                    },
                    {
                        title: '休息区',
                        image: require('../../assets/images/education/retail-card13.png'),
                        content: '休息区可以让客户在购物之余还能放松心情、恢复体力从而提升客户的满意度。在休息区陈列茶几、椅子、智能充电宝等，同时放置一个电视用于展示当天店铺活动或促销品信息，让客户在休息之余能实时了解店铺活动信息。'
                    },
                    {
                        title: '智能结算区',
                        image: require('../../assets/images/education/retail-card14.png'),
                        content: '在付款区配备一台小型工作站，用于体验中心监控设备信息的采集，电子价签和电视信息的发送。同时在结算时引入人脸识别系统和自助收银结算系统来提高收银结算效率和顾客信息管理。然后通过大数据对顾客的消费记录进行分析，了解顾客的消费习惯、顾客最关注的产品，从而通过学生的线上商城进行精准营销和优惠推送。'
                    },
                    {
                        title: '体验中心进出口',
                        image: require('../../assets/images/education/retail-card15.png'),
                        content: '体验中心进出口处引入人脸闸机及智能检测仪。人脸识别闸机通过3D面部识别使客户刷脸快速进入体验中心，人脸闸机自动统计通行人数方便学生计算体验中心当天的客流量。客户要离开体验中心时需要在检测仪处稍作停留，智能检测仪将对商品支付状态进行检测。当检查到未支付商品，将提示客户将商品放回货架或到智能结算区完成支付。'
                    },
                ],
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('scroll', this.onScroll, true)
            })
        },
        methods: {
            // 滚动触发按钮高亮
            onScroll(e) {
                let scrollItems = document.querySelectorAll('.box-title')
                for (let i = scrollItems.length - 1; i >= 0; i--) {
                    // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
                    let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop
                    if (judge) {
                        this.activeStep = i + 1
                        break
                    }
                }
            },
            // 点击切换锚点
            jump(item) {
                document.getElementById(item.to).scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block: "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-box {
        width: 100%;

        .step-box {
            position: sticky;
            float: left;
            height: 200px;
            top: 100px;
            z-index: 1;
            cursor: pointer;
        }

        .main-box-contain {
            background-image: url("../../assets/images/education/circle.png");
            background-repeat: no-repeat;
            background-size: cover;

            .box-title {
                text-align: center;
                font-size: 30px;
                color: #333333;
                position: relative;

                &:after {
                    content: '';
                    width: 50px;
                    height: 4px;
                    background: #584EEE;
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    margin-left: -20px;
                }
            }

            .bottom-box-content {
                padding-top: 60px;
                width: 1150px;
                margin: 0 auto;

                .title-box {
                    display: flex;
                    flex-direction: column;
                    text-align: right;

                    &.left {
                        align-items: flex-start;
                    }

                    &.right {
                        align-items: flex-end;
                    }

                    .title-index {
                        width: 94px;
                        height: 94px;
                        background: linear-gradient(129deg, #60C4FF 0%, #717EFE 100%);
                        font-weight: bold;
                        font-size: 72px;
                        color: #FFFFFF;
                        line-height: 120px;
                    }

                    .title-text {
                        font-size: 20px;
                        color: #444444;
                        line-height: 36px;
                        margin-top: 10px;
                    }
                }

                .main-contain {
                    width: 1150px;
                    padding-top: 30px;
                    margin: 0 auto;

                    .main-contain-text {
                        margin-top: 40px;
                        text-indent: 2em;
                        font-size: 14px;
                        color: #444444;
                        line-height: 34px;
                    }

                    .double-image-box {
                        position: relative;
                        display: flex;
                        height: 480px;
                        margin-top: 70px;

                        .right-image {
                            position: absolute;
                            left: 470px;
                            top: 80px;
                        }
                    }

                    .img-box {
                        padding: 40px 0 30px;
                        text-align: center;
                    }
                }
            }

            .card-item {
                width: 453px;
                min-height: 596px;
                background: #FFFFFF;
                box-shadow: 0px 0px 31px 1px rgba(5, 4, 67, 0.15);
                opacity: 0.9;
                z-index: 1;
                margin-right: 60px;

                .top-item-box {
                    height: 283px;
                    position: relative;

                    .top-item-title {
                        width: 100%;
                        height: 39px;
                        background: #000000;
                        opacity: 0.5;
                        display: flex;
                        color: #FFFFFF;
                        font-size: 12px;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: 0;
                    }
                }

                .bottom-item-box {
                    .bottom-item-contain {
                        padding: 30px 30px 40px;

                        .bottom-item-title {
                            font-size: 14px;
                            color: #222222;
                            line-height: 28px;
                            text-align: center;
                        }

                        .bottom-item-content {
                            margin-top: 30px;
                            font-size: 12px;
                            color: #444444;
                            line-height: 27px;
                            text-indent: 2em;
                        }
                    }
                }
            }

            &.box1 {
                background-color: #ffffff;

                .top-card {
                    width: 1150px;
                    margin: 0 auto;

                    .top-card-contain {
                        padding-top: 30px;

                        .right-card-box {
                            .card-text {
                                margin-top: 40px;
                                font-size: 14px;
                                color: #333333;
                                line-height: 36px;
                                text-indent: 2em;
                            }
                        }
                    }
                }

                .bottom-card {
                    margin: 0 auto;
                    padding-bottom: 120px;

                    .bottom-card-contain {
                        width: 1150px;
                        min-height: 684px;
                        background: #F2F2F2;
                        margin: 30px auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .bottom-card-list {
                        width: 1150px;
                        margin: 0 auto;
                        display: flex;
                        flex-wrap: wrap;

                        .card-list-item {
                            display: flex;
                            width: 560px;
                            height: 250px;
                            background: #FFFFFF;
                            box-shadow: 0px 3px 16px 0px rgba(8, 51, 149, 0.2);
                            border-radius: 10px;
                            margin-right: 30px;
                            margin-top: 40px;

                            &:nth-of-type(2n) {
                                margin-right: 0;
                            }

                            .card-right-contain {
                                padding: 30px 40px 0 20px;

                                .card-title {
                                    font-size: 18px;
                                    font-weight: 300;
                                    color: #444444;
                                    line-height: 36px;
                                }

                                .card-content {
                                    margin-top: 20px;
                                    padding-bottom: 30px;
                                    font-size: 14px;
                                    color: #444444;
                                    font-weight: 300;
                                    line-height: 30px;
                                }
                            }
                        }
                    }

                    .bottom-img-contain {
                        width: 1150px;
                        height: 394px;
                        background: #F7FAFB;
                        margin: 50px auto 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            &.box2 {
                background-color: #FFFFFF;
                background-image: url("../../assets/images/education/retail-card-back.png");
                background-size: cover;
                padding-bottom: 40px;

                .top-box-content {
                    width: 1150px;
                    margin: 0 auto;
                    padding-top: 50px;
                }

                .main-box {
                    width: 1150px;
                    height: 480px;
                    background: #ffffff;
                    margin: 60px auto 40px;
                    display: flex;

                    .main-contain {
                        padding: 100px 40px 40px 50px;
                        display: flex;

                        .main-left {
                            font-size: 14px;
                            font-weight: 300;
                            color: #444444;
                            line-height: 36px;
                        }

                        .main-right {
                            margin-top: 50px;
                        }
                    }
                }
            }

            &.box3 {
                background: #ffffff;
                padding-bottom: 90px;

                .top-box-content {
                    width: 1150px;
                    margin: 0 auto;
                    padding-top: 50px;
                }

                .card-list {
                    width: 1150px;
                    margin: 0 auto;
                    padding-top: 100px;

                    .card-list-item {
                        display: flex;
                        height: 333px;
                        margin-top: 50px;

                        &:first-child {
                            margin-top: 0;
                        }

                        &.right {
                            flex-direction: row-reverse;
                        }

                        .card-item-left {
                            background: #F7FAFB;

                            .card-left-contain {
                                padding: 50px 70px 0 50px;
                                line-height: 34px;

                                .card-title {
                                    font-size: 18px;
                                    color: #222222;
                                }

                                .card-content {
                                    font-size: 14px;
                                    color: #444444;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }

                .bottom-image-box {
                    width: 1150px;
                    margin: 60px auto 0;
                    background: #F7FAFB;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &.box4 {
                background-color: #FFFFFF;
                background-image: url("../../assets/images/about/lift-hand.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center bottom;

                .bottom-box-content {
                    padding-bottom: 100px;
                }

            }
        }
    }

</style>