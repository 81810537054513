<template>
    <div class="main-box">
        <el-steps class="step-box" direction="vertical" space="50px"
                  :active="activeStep">
            <el-step v-for="(item, index) in stepsArr" :key="index" :title="item.title"
                     @click.native="jump(item)"></el-step>
        </el-steps>
        <div class="main-box-contain box1">
            <div class="top-card">
                <div class="top-card-contain">
                    <div class="right-card-box">
                        <div class="box-title" id="box-title1">总体介绍</div>
                        <div class="card-text">
                            新媒体电商产教融合实践基地规划总面积600平方米，打造以新媒体电商教学、项目实训、平台实战为主要内容的实践基地，建成集产业、专业服务、配套服务于一体的高端新媒体基地。基地对接全国首个MCN全产业链聚合平台，为学生提供红人签约孵化、流量分成、创意支持、技术支持、渠道分发、商业变现等保障措施，打造以内容生产和运营为内核，链入多种业态形式的新媒体电商产教融合实践基地，为电子商务专业人才培养提供有力的支持。
                        </div>
                        <div class="card-list">
                            <div class="card-list-item">场地面积：600平方米（要求能容纳至少150个学生同时进行教学实训）；</div>
                            <div class="card-list-item">功能区规划：素材采集中心、短视频运营中心、自媒体运营中心、美工运营中心、网红直播中心等；</div>
                            <div class="card-list-item">
                                基地主要功能：根据学校教学需求以及新媒体企业人才需求，实训室的主要功能有：理论知识学习、实训训练、项目实战、综合训练、创新创业孵化等；
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-card">
                <el-carousel :interval="4000" type="card" height="500px" class="home-banner">
                    <el-carousel-item :key="bannerItem.id" v-for="bannerItem in bannerList">
                        <img :src="bannerItem.banner_img" class="banner-img">
                        <div class="banner-content">
                            <div class="banner-title">{{bannerItem.title}}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="main-box-contain box2">
            <div class="top-box-content">
                <div class="box-title" id="box-title2">建设内容</div>
            </div>
            <div class="bottom-box-content">
                <div class="title-box left">
                    <div class="title-index">01</div>
                    <div class="title-text">自媒体运营中心</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        自媒体实训中心规划面积100㎡，完全以企业真实工作环境进行布置和装修，充分考虑教师教学和学生学习需求，配置交互智能教学一体机、五角屏风电脑桌，共设置40工位，每工位配备电脑，从环境布置上更方便学生进行团队协作。此外，根据自媒体行业发展现状设置微信公众号运营区、微博运营区、百度百家号运营区、大鱼号运营区、今日头条号运营区、知乎运营区、网易号运营区、搜狐号运营区八大运营专区，学生通过各专区的轮岗的学习，能够全面了解、掌握自媒体平台的运营方法与技巧。自媒体实训中心引入《新媒体营销概论》《网络编辑》《新媒体文章运营》课程、自媒体实战项目，在信息化教学平台完成教学、实训、实战，培养学生从图片、视频素材制作到平台运营的完整流程，大大增强了实践能力。
                    </div>
                    <div class="img-box">
                        <img src='../../assets/images/education/media-card1.png' alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box-contain box3">
            <div class="bottom-box-content">
                <div class="title-box right">
                    <div class="title-index">02</div>
                    <div class="title-text">短视频运营中心</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        短视频运营中心规划面积100㎡，以新媒体企业短视频工作室为样版搭建而成，配置交互智能教学一体机、屏风电脑桌，每张电脑桌设置5个工位，共预设40工位，每工位引入高配置电脑，以满足视频剪辑的硬件要求，承担视频剪辑与合成、新媒体文章运营的功能。根据短视频平台发展现状与趋势，运营中心分设抖音短视频运营区、快手短视频运营区、火山短视频运营区、微视短视频运营区四大头部平台运营专区，学生通过轮岗可以专门训练到各大视频平台的运营规则、推荐机制、内容创作技巧等技能。短视频运营中心引入《新媒体文章运营》课程、新媒体教学实战软件、视频实战项目、新媒体教学实战软件，通过信息化教学平台完成教学、实训、实战，培养学生掌握视频剪辑软件的使用以及各类视频的拍摄与剪辑技巧，熟悉掌握主流视频平台的规则和运营流程，并可对接真实视频平台做实战。
                    </div>
                    <div class="img-box">
                        <img src='../../assets/images/education/media-card2.png' alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box-contain box4">
            <div class="bottom-box-content">
                <div class="title-box left">
                    <div class="title-index">03</div>
                    <div class="title-text">美工运营中心</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        美工实训中心规划面积120㎡，按照企业真实工作环境进行布置和装修，配置交互智能教学一体机，根据企业对美工的技能要求设置海报设计区、视觉营销区、网页设计区、广告设计区，共预设40工位，每工位配备满足图像处理能力的学生电脑，全方位满足师生从教学、实训，到实战的全流程；实训中心后方设置作品展示墙，用于展示学生优秀的图片，营造良好的学习氛围。美工实训中心通过引入《图形图像制作》《电商美工》课程、美工实战项目，通过信息化教学平台完成教学、实训、实战，帮助学生熟练掌握PS软件使用、图片后期处理、各类图片海报的设计、图片拍摄技巧、图片后期处理技巧等技能，最终以企业项目实战的形式完善自身美工知识与技能。
                    </div>
                    <div class="img-box">
                        <img src='../../assets/images/education/media-card3.png' alt="">
                    </div>
                </div>
            </div>
            <div class="bottom-box-content">
                <div class="title-box right">
                    <div class="title-index">04</div>
                    <div class="title-text">素材采集中心</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        新媒体电商产教融合实践基地搭设素材采集中心，规划面积120㎡，下设图片拍摄区、视频摄影区、音频采集区、拍摄准备区。利用配备的相机套装、摄影套装、录音设备等器材进行图片拍摄、短视频拍摄、音频采集，能极大地满足自媒体实训中心、美工实训中心和短视频制作中心的素材采集需求，培养学生内容原创能力和版权意识。
                    </div>
                    <div class="img-box">
                        <img src='../../assets/images/education/media-card4.png' alt="">
                    </div>
                </div>
            </div>
            <div class="card-contain">
                <div class="card-box">
                    <div class="card-item" v-for="item in cardList1" :key="item.title">
                        <div class="item-box">
                            <div class="top-item-box" :style="{backgroundImage:'url('+item.image+')'}">
                                <div class="top-item-title">{{item.title}}</div>
                            </div>
                            <div class="bottom-item-box">
                                <div class="bottom-item-contain">
                                    <div class="bottom-item-title">{{item.name}}</div>
                                    <div class="bottom-item-content">{{item.content}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-back"></div>
                <div class="card-box bottom">
                    <div class="card-item" v-for="item in cardList2" :key="item.title">
                        <div class="item-box">
                            <div class="top-item-box" :style="{backgroundImage:'url('+item.image+')'}">
                                <div class="top-item-title">{{item.title}}</div>
                            </div>
                            <div class="bottom-item-box">
                                <div class="bottom-item-contain">
                                    <div class="bottom-item-title">{{item.name}}</div>
                                    <div class="bottom-item-content">{{item.content}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box-contain box5">
            <div class="bottom-box-content">
                <div class="title-box left">
                    <div class="title-index">05</div>
                    <div class="title-text">网红直播中心</div>
                </div>
                <div class="main-contain">
                    <div class="main-contain-text">
                        网红直播中心规划面积130㎡，根据行业直播室的标准进行搭建，下设休息区、直播准备区以及各类型直播间，可用于游戏类、美妆服饰类、美食类、才艺展示类等类型的直播，各直播间均充分考虑建声设计方案，通过吸声墙体、地面、门窗的特殊设计，保证独立、安静的直播环境。
                    </div>
                    <div class="main-contain-text">
                        网红直播中心以网红孵化为主要功能，引入实战课程《网红运营实战》，通过信息化教学平台帮助学生掌握直播场景搭建、沟通互动技巧、着装与化妆技巧等，通过斗鱼平台、YY平台、淘宝达人平台等的项目实战，培养包括游戏类、美食类、美妆服饰类、才艺展示类等多种类型在内的网红主播。
                    </div>
                    <div class="img-box">
                        <img src='../../assets/images/education/media-card9.png' alt="">
                    </div>
                </div>
            </div>
            <div class="card-box">
                <div class="card-item" v-for="item in cardList3" :key="item.title">
                    <div class="item-box">
                        <div class="top-item-box" :style="{backgroundImage:'url('+item.image+')'}">
                            <div class="top-item-title">{{item.title}}</div>
                        </div>
                        <div class="bottom-item-box">
                            <div class="bottom-item-contain">
                                <div class="bottom-item-title">{{item.name}}</div>
                                <div class="bottom-item-content">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box-contain box6">
            <div class="top-box-content">
                <div class="box-title" id="box-title3">项目保障措施</div>
            </div>
            <div class="bottom-box-content">
                <div class="main-contain">
                    <div class="main-contain-text">
                        一课教育通过联合“院校、企业、行业”打造新媒体电商教育生态，与全国首个MCN全产业链聚合平台海西MCN产业基地达成战略合作，快速获取新媒体产业数据资源信息和众多企业实战项目，在与院校合作的过程中，能够提供行业领先的教学支持、项目支持、导师支持、创业支持、数据支持和平台支持，助力院校培养实战应用型新媒体电商人才。
                    </div>
                </div>
            </div>
            <div class="icon-box">
                <div class="icon-item" v-for="item in iconList">
                    <div class="left-box">
                        <img :src="item.image" alt="">
                    </div>
                    <div class="right-box">
                        <div class="right-title">{{item.name}}</div>
                        <div class="right-text">{{item.content}}</div>
                    </div>
                </div>
            </div>
            <OfficialFooter style="background: rgba(3,3,47,0.85)"/>
        </div>
    </div>
</template>

<script>
    import OfficialFooter from "../OfficialFooter";

    export default {
        name: "newMedia",
        components: {
            OfficialFooter
        },
        data() {
            return {
                stepsArr: [
                    {title: '总体介绍', to: 'box-title1'},
                    {title: '建设内容', to: 'box-title2'},
                    {title: '项目保障措施', to: 'box-title3'},
                ],
                activeStep: 1,
                scrollTop: 0,
                bannerList: [
                    {
                        id: 1,
                        banner_img: require('../../assets/images/education/base1.jpg'),
                        title: '（基地平面图）',
                    },
                    {
                        id: 2,
                        banner_img: require('../../assets/images/education/base2.jpg'),
                        title: '（基地整体效果图）',
                    },
                    {
                        id: 3,
                        banner_img: require('../../assets/images/education/base3.jpg'),
                        title: '（基地前台效果图）',
                    },
                ],
                cardList1: [
                    {
                        title: '（视频拍摄区效果图）',
                        name: '视频摄影区',
                        image: require('../../assets/images/education/media-card5.png'),
                        content: '视频摄影区以新媒体视频摄影工作室真实工作环境搭建而成，配备全套视频摄影设备，包括单反相机、摄像套装、滑轨、演播台、背景布、高配电脑等，并设置隔音墙，确保视频录制时不受外界噪音干扰。视频摄影区引入《视频剪辑与后期处理》课程，帮助学生掌握视频剪辑基本流程、视频拍摄工具的使用、Pr软件使用、淘宝短视频、风景短视频、美食短视频、宠物类短视频、Vlog类短视频等不同类型视频的拍摄和剪辑，并能够与音频采集区和短视频运营中心紧密配合，完成视频的拍摄、剪辑、合成全流程，并最终将作品对接到主流短视频平台。'
                    },
                    {
                        title: '（图片拍摄区效果图）',
                        name: '图片拍摄区',
                        image: require('../../assets/images/education/media-card6.png'),
                        content: '图片拍摄区基于行业领先的摄影工作室搭建而成，配备专业、先进的拍摄器材，包括专业单反相机、灯光套装、镜头、背景布等，可以满足室内各类型人物、物品拍摄和室外场景的拍摄需求；图片拍摄区引入《商品拍摄与后期处理》课程，帮助学生从熟悉相机操作、灯光的布置到具体产品的拍摄技巧，课程内容包括商品拍摄基础、服装饰品类商品的拍摄、美容护肤类商品的拍摄、玻璃和金属制品类商品的拍摄、数码家电类商品的拍摄等，将能够为美工运营中心提供丰富图片素材，为自媒体实训中心提供平台运营所需的原创高清图片。'
                    },
                ],
                cardList2: [
                    {
                        title: '（控制室效果图）',
                        name: '音频采集区',
                        image: require('../../assets/images/education/media-card7.png'),
                        content: '音频采集区的建设结合音频录制的需求，利用玻璃隔间将控制室与录音室分离，并采用行业领先的噪音隔绝方案，确保音频采集时的室内环境，配套专业音频采集系统，能够为视频录制、合成、调节提供强大的支持。学生在拍摄或者剪辑视频时，凡涉及音频录制、合成、剪辑等，都可以通过采集区内的专业设备完成。'
                    },
                    {
                        title: '（拍摄准备区效果图）',
                        name: '拍摄准备区',
                        image: require('../../assets/images/education/media-card8.png'),
                        content: '拍摄准备区配置试衣间、货架、化妆桌桌椅等，作为拍摄/录制前的准备场地，用于制定拍摄/配音方案、准备摄影素材、分工协作讨论，货架上摆放各式商品，供学生实际拍摄时使用，将准备工作做好之后再进行实拍，以免长时间占用摄影区。'
                    },
                ],
                cardList3: [
                    {
                        title: '（开播准备区效果图）',
                        name: '开播准备区',
                        image: require('../../assets/images/education/media-card10.png'),
                        content: '开播准备区设置沙发、饮水机、更衣间，作为准备开播学生的等待室，也可以为学生练习和调节情绪提供场地。此外，准备区还设有商品陈列货架，直播中所需的各种产品都陈列于此，学生直播时可尝试不同的商品。'
                    },
                    {
                        title: '（休息区效果图）',
                        name: '休息区',
                        image: require('../../assets/images/education/media-card11.png'),
                        content: '休息区设置电子直播大屏，通过接收各直播间的信号实时接收直播画面，教师和直播小组成员可以通过大屏直接观看直播情况，直播结束后主播也可以回看自己的直播内容，对其中不足的地方做出总结。'
                    },
                    {
                        title: '(才艺展示直播间效果图)',
                        name: '直播间',
                        image: require('../../assets/images/education/media-card12.png'),
                        content: '直播间完全基于行业标准的环境场景进行搭建，根据直播内容的不同设置游戏类直播间、美妆服饰类直播间、美食类直播间、才艺展示类直播间，各个直播间配置电脑、手机、麦克风、摄像头、灯光器材等硬件，保证直播时画面清晰、声音稳定、光线均匀。'
                    },
                ],
                iconList: [
                    {
                        name: '教学支持',
                        image: require('../../assets/images/education/support1.png'),
                        content: '全程化新媒体培养体系'
                    },
                    {
                        name: '项目支持',
                        image: require('../../assets/images/education/support2.png'),
                        content: '新媒体电商企业项目对接'
                    },
                    {
                        name: '平台支持',
                        image: require('../../assets/images/education/support3.png'),
                        content: '新媒体教学实战平台、信息化教学平台作为支撑'
                    },
                    {
                        name: '创业支持',
                        image: require('../../assets/images/education/support4.png'),
                        content: '成立创业孵化专项基金，为学生创业发展助力'
                    },
                    {
                        name: '数据支持',
                        image: require('../../assets/images/education/support5.png'),
                        content: '提供新媒体电商行业大数据与行业调研分析报告'
                    },
                    {
                        name: '导师支持',
                        image: require('../../assets/images/education/support6.png'),
                        content: '行业企业导师入校进行教学指导、项目指导、创业指导'
                    },
                ],
                scroll: '',
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('scroll', this.onScroll, true)
            })
        },
        methods: {
            // 滚动触发按钮高亮
            onScroll(e) {
                let scrollItems = document.querySelectorAll('.box-title')
                for (let i = scrollItems.length - 1; i >= 0; i--) {
                    // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
                    let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop
                    if (judge) {
                        this.activeStep = i + 1
                        break
                    }
                }
            },
            // 点击切换锚点
            jump(item) {
                document.getElementById(item.to).scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block: "start"
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-box {
        width: 100%;

        .step-box {
            position: sticky;
            float: left;
            height: 200px;
            top: 100px;
            z-index: 1;
            cursor: pointer;
        }

        .main-box-contain {
            background-image: url("../../assets/images/education/circle.png");
            background-repeat: no-repeat;
            background-size: cover;

            .box-title {
                text-align: center;
                font-size: 30px;
                color: #333333;
                position: relative;

                &:after {
                    content: '';
                    width: 50px;
                    height: 4px;
                    background: #584EEE;
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    margin-left: -20px;
                }
            }

            .bottom-box-content {
                padding-top: 60px;
                width: 1150px;
                margin: 0 auto;

                .title-box {
                    position: relative;
                    height: 77px;

                    &.left {
                        text-align: left;
                    }

                    &.right {
                        text-align: right;

                        .title-text {
                            right: 0;
                        }
                    }

                    .title-index {
                        font-weight: bold;
                        font-size: 100px;
                        color: #584EEE;
                        line-height: 36px;
                        opacity: 0.3;
                    }

                    .title-text {
                        position: absolute;
                        bottom: 25px;
                        font-size: 20px;
                        color: #444444;
                        line-height: 36px;
                    }
                }

                .main-contain {
                    width: 1150px;
                    padding-top: 30px;
                    margin: 0 auto;

                    .main-contain-text {
                        text-indent: 2em;
                        font-size: 14px;
                        color: #444444;
                        line-height: 34px;
                    }

                    .img-box {
                        padding: 40px 0 30px;
                        text-align: center;
                    }
                }
            }

            .card-item {
                width: 453px;
                min-height: 596px;
                background: #FFFFFF;
                box-shadow: 0px 0px 31px 1px rgba(5, 4, 67, 0.15);
                opacity: 0.9;
                z-index: 1;
                margin-right: 60px;

                .top-item-box {
                    height: 283px;
                    position: relative;

                    .top-item-title {
                        width: 100%;
                        height: 39px;
                        background: #000000;
                        opacity: 0.5;
                        display: flex;
                        color: #FFFFFF;
                        font-size: 12px;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: 0;
                    }
                }

                .bottom-item-box {
                    .bottom-item-contain {
                        padding: 30px 30px 40px;

                        .bottom-item-title {
                            font-size: 14px;
                            color: #222222;
                            line-height: 28px;
                            text-align: center;
                        }

                        .bottom-item-content {
                            margin-top: 30px;
                            font-size: 12px;
                            color: #444444;
                            line-height: 27px;
                            text-indent: 2em;
                        }
                    }
                }
            }

            &.box1 {

                .top-card {
                    width: 1150px;
                    margin: 0 auto;

                    .top-card-contain {
                        padding-top: 100px;

                        .right-card-box {
                            .card-text {
                                margin-top: 40px;
                                font-size: 14px;
                                color: #333333;
                                line-height: 36px;
                                text-indent: 2em;
                            }

                            .card-list {
                                margin-top: 36px;

                                .card-list-item {
                                    font-weight: 300;
                                    font-size: 14px;
                                    line-height: 36px;
                                    color: #444444;
                                    position: relative;
                                    margin-left: 20px;

                                    &:before {
                                        position: absolute;
                                        content: '';
                                        width: 8px;
                                        height: 8px;
                                        background: linear-gradient(-53deg, #584EEE 0%, #75B4FB 100%);
                                        border-radius: 50%;
                                        left: -20px;
                                        top: 14px;
                                    }
                                }
                            }

                        }
                    }
                }

                .bottom-card {
                    background: #F7FAFB;
                    margin: 30px 0;

                    .home-banner {
                        width: 1150px;
                        margin: 0 auto;
                        padding: 40px 0;
                        text-align: center;

                        ::v-deep .el-carousel__indicators--outside {
                            display: none;
                        }

                        .banner-content {
                            font-size: 14px;
                            color: #444444;
                            line-height: 36px;
                        }
                    }
                }
            }

            &.box2 {
                background-color: #FFFFFF;

                .top-box-content {
                    width: 1150px;
                    margin: 0 auto;
                    padding-top: 100px;

                    .title-icon-box {
                        width: 1150px;
                        margin-top: 40px;
                        display: flex;

                        .icon-item {
                            width: 108px;
                            height: 120px;
                            margin-right: 65px;
                            cursor: pointer;

                            &:nth-of-type(7) {
                                margin-right: 0;
                            }

                            &.active {
                                box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                                border-radius: 6px;
                                transition: 0.5s;
                            }

                            .item-content {
                                padding: 20px;
                                text-align: center;
                                position: relative;

                                .item-title {
                                    font-size: 16px;
                                    margin-top: 5px;
                                }

                                .item-bottom-line {
                                    width: 30px;
                                    height: 3px;
                                    position: absolute;
                                    bottom: 0;
                                    left: 38px;
                                }
                            }
                        }
                    }
                }

                .main-box {
                    width: 1150px;
                    padding: 200px 0;
                    margin: 0 auto;
                    display: flex;

                    .left-card-box {
                        padding: 100px 150px 100px 50px;

                        .card-title {
                            font-size: 24px;
                            font-weight: 300;
                            color: #222222;
                            text-align: center;
                        }

                        .card-text {
                            margin-top: 50px;
                            width: 440px;
                            font-size: 14px;
                            color: #666666;
                            font-weight: 300;
                            line-height: 24px;
                            text-indent: 2em;
                        }
                    }
                }
            }

            &.box3 {
                background: #F7FAFB;
            }

            &.box4 {
                .card-box {
                    width: 1150px;
                    margin: 0 auto;
                    padding-top: 60px;
                    display: flex;

                    &.bottom {
                        justify-content: flex-end;
                    }
                }

                .card-contain {
                    position: relative;
                    padding-bottom: 60px;

                    .card-back {
                        position: absolute;
                        height: 917px;
                        width: 100%;
                        top: 160px;
                        background-image: url("../../assets/images/education/media-card-back.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: top;
                    }
                }
            }

            &.box5 {
                background-color: #F7FAFB;
                background-image: unset;

                .card-box {
                    display: flex;
                    width: 1150px;
                    margin: 0 auto;
                    padding: 50px 0;

                    .card-item {
                        width: 369px;
                        height: 486px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 31px 1px rgba(5, 4, 67, 0.15);
                        opacity: 0.9;

                        .top-item-box {
                            height: 232px;
                        }
                    }
                }
            }

            &.box6 {
                background-color: #FFFFFF;
                background-image: url("../../assets/images/about/lift-hand.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center bottom;

                .top-box-content {
                    padding-top: 100px;
                }

                .icon-box {
                    width: 1150px;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0 0 120px;

                    .icon-item {
                        width: calc(33% - 160px);
                        margin-right: 160px;
                        margin-top: 120px;
                        display: flex;

                        &:nth-of-type(3n) {
                            margin-right: 0;
                            margin-left: 100px;
                        }

                        .left-box {
                            display: flex;
                            align-items: center;
                        }

                        .right-box {
                            margin-left: 20px;
                            min-width: 230px;
                            font-size: 18px;
                            color: #444444;
                            font-weight: 300;
                            line-height: 34px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .right-text {
                                font-size: 14px;
                                max-height: 30px;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }
    }

</style>